<template>
  <div class="csn-support">
    <SupportSidebar v-if="rendersDesktopSupportSidebar" />

    <div v-else>
      <div class="csn-support-return">
        <span class="csn-support-return-text">
          {{ t('go_to_section') }}
        </span>

        <button
          :disabled="SHOWS_SUPPORT_SIDE_MENU"
          @click="goBackToSectionMenu"
          class="csn-support-return-arrow-back"
          :class="{
            'csn-support-return-arrow-back-disabled': SHOWS_SUPPORT_SIDE_MENU,
          }"
        >
          <SlideArrow />
        </button>
      </div>
    </div>

    <div class="csn-support-text-container">
      <Message :type="errorMessageType" :message="message" :hasBottom="true" />

      <div class="csn-support-item">
        <h2 class="csn-support-item-title">
          {{ title }}
        </h2>
        <div v-html="content" class="csn-support-content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

import {
  SUPPORT_COMPONENT,
  Module,
  SCREEN_WIDTH,
  SHOWS_SUPPORT_SIDE_MENU,
  TOGGLE_SUPPORT_SIDE_MENU,
  DROP_MENU,
  MessageTypeEnum,
} from '@/constants'
import { MainApi } from '@/api'
import { ResponseState } from '../../constants'

export default {
  name: SUPPORT_COMPONENT,
  components: {
    SupportSidebar: () => import('./SupportSidebar.vue'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    Message: () => import('@/components/atoms/Message'),
  },
  data: () => ({
    slug: '',
    title: '',
    content: '',
    message: '',
  }),
  computed: {
    ...mapState(Module.DEVICE, [SCREEN_WIDTH]),
    ...mapState(Module.MAIN, [SHOWS_SUPPORT_SIDE_MENU]),
    rendersDesktopSupportSidebar() {
      return this.SCREEN_WIDTH >= 1550
    },
    t() {
      return this.$createComponentTranslator(SUPPORT_COMPONENT)
    },
    errorMessageType() {
      return MessageTypeEnum.FAILURE
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_SUPPORT_SIDE_MENU, DROP_MENU]),
    goBackToSectionMenu() {
      this.DROP_MENU()
      this.TOGGLE_SUPPORT_SIDE_MENU(!this.SHOWS_SUPPORT_SIDE_MENU)
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler({ params }) {
        this.slug = params?.slug || this.$router?.history?.current?.params?.slug

        const { data, state, msg } = await MainApi.getCmsPageDetails({
          urlSlug: params?.slug,
        })

        if (state === ResponseState.ERROR) {
          this.message = msg
          return
        }

        const { title, content, pageTitle } = data

        this.title = title
        this.content = content
        document.title = pageTitle
      },
    },
  },
}
</script>
